import { useGameStore } from '~/stores/games.store';
import { useSiteStore } from '~/stores/site.store';
import { useLocaleStore } from '~/stores/locale.store';

export const useCommonGaming = () => {
  const gameStore = useGameStore();
  const siteStore = useSiteStore();
  const localeStore = useLocaleStore();
  const { $gameService } = useServices();
  const route = useRoute();
  return { $gameService, route, gameStore, siteStore, localeStore };
};

export const useGetVertical = async (
  vertical: string,
  skipStateUpdate: boolean = false
) => {
  const common = useCommonGaming();
  common.gameStore.clearCompleteState();
  common.siteStore.setLoading(true);
  return await common.$gameService
    .fetchCategories({
      regionCode: common.siteStore.getRegionCode,
      channel: 'WebDesktop',
      count: 25,
      gameCount: 25,
      languageCode: common.localeStore.getLocale || 'en-US',
      vertical: vertical,
      currency: 'USD',
    })
    .then((verticalData) => {
      if (!skipStateUpdate) common.gameStore.setGameStore(verticalData);
      common.siteStore.setLoading(false);
      return verticalData;
    })
    .catch((e) => {
      common.siteStore.setLoading(false);
      console.error(e);
    });
};

export const useGetCategory = async (
  vertical: string,
  category: string,
  skipVertical: boolean = false,
  skipStateUpdate: boolean = false
) => {
  const common = useCommonGaming();

  const stateCategory = computed(() =>
    common.gameStore.returnFullCategory(category)
  );

  const categoryGames = common.gameStore.returnCategory({
    category,
    provider: '',
    theme: '',
  });

  if (typeof stateCategory.value === 'undefined' && !skipVertical)
    await useGetVertical(vertical);

  return await common.$gameService
    .fetchCategory({
      regionCode: common.siteStore.getRegionCode,
      channel: 'WebDesktop',
      skip: categoryGames.length,
      limit: 50,
      count: 50,
      languageCode: common.localeStore.getLocale || 'en-US',
      vertical,
      category,
      currency: 'USD',
    })
    .then((verticalData) => {
      if (!skipStateUpdate) common.gameStore.addGames(verticalData);
      return verticalData;
    });
};
